<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="6">
        <h2>Data Pendukung</h2>
      </v-col>
      <v-col cols="6" class="text-right">
        <dokpendukungModalEdit />
      </v-col>
    </v-row>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card :loading="ldata">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Jenis Dokumen</th>
              <th class="text-left">Nomor</th>
              <th class="text-left">Tanggal</th>
              <th class="text-left">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NPWP</td>
              <td>{{ npwpNomor }}</td>
              <td>{{ npwpTanggal }}</td>
              <td>{{ npwpStatus.nama }}</td>
            </tr>
            <tr>
              <td>Kartu Pegawai</td>
              <td>{{ kpNomor }}</td>
              <td>{{ kpTanggal }}</td>
              <td>{{ kpStatus.nama }}</td>
            </tr>
            <tr>
              <td>Kartu Istri</td>
              <td>{{ ksNomor }}</td>
              <td>{{ ksTanggal }}</td>
              <td>{{ ksStatus.nama }}</td>
            </tr>
            <tr>
              <td>TASPEN</td>
              <td>{{ tpNomor }}</td>
              <td>{{ tpTanggal }}</td>
              <td>{{ tpStatus.nama }}</td>
            </tr>
            <tr>
              <td>Asuransi Kesehatan</td>
              <td>{{ akNomor }}</td>
              <td>{{ akTanggal }}</td>
              <td>{{ akStatus.nama }}</td>
            </tr>
            <tr>
              <td>Kartu Keluarga</td>
              <td>{{ kkNomor }}</td>
              <td>{{ kkTanggal }}</td>
              <td>{{ kkStatus.nama }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import dokpendukungModalEdit from "@/components/asn/profil/dokpendukung/ModalEdit";

import refreshView from "@/store/asn/dokpendukung/viewDokpendukung";

export default {
  name: "dokpendukung",

  components: {
    dokpendukungModalEdit,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    dokpendukungs: [],

    ldata: true,

    npwpNomor: "",
    npwpTanggal: "",
    npwpStatus: "",
    kpNomor: "",
    kpTanggal: "",
    kpStatus: "",
    ksNomor: "",
    ksTanggal: "",
    ksStatus: "",
    tpNomor: "",
    tpTanggal: "",
    tpStatus: "",
    akNomor: "",
    akTanggal: "",
    akStatus: "",
    kkNomor: "",
    kkTanggal: "",
    kkStatus: "",
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true
      const url = process.env.VUE_APP_ASN + "pendukung/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false
          refreshView.commit("refreshData", false);
          this.npwpNomor = response.data.mapData.DataPendukung.npwp.nomor;
          this.npwpTanggal = response.data.mapData.DataPendukung.npwp.tanggal;
          this.npwpStatus = response.data.mapData.DataPendukung.npwp.status;
          this.kpNomor =
            response.data.mapData.DataPendukung.kartuPegegawai.nomor;
          this.kpTanggal =
            response.data.mapData.DataPendukung.kartuPegegawai.tanggal;
          this.kpStatus =
            response.data.mapData.DataPendukung.kartuPegegawai.status;
          this.ksNomor = response.data.mapData.DataPendukung.karSuIs.nomor;
          this.ksTanggal = response.data.mapData.DataPendukung.karSuIs.tanggal;
          this.ksStatus = response.data.mapData.DataPendukung.karSuIs.status;
          this.tpNomor = response.data.mapData.DataPendukung.taspen.nomor;
          this.tpTanggal = response.data.mapData.DataPendukung.taspen.tanggal;
          this.tpStatus = response.data.mapData.DataPendukung.taspen.status;
          this.akNomor =
            response.data.mapData.DataPendukung.asuransiKesehatan.nomor;
          this.akTanggal =
            response.data.mapData.DataPendukung.asuransiKesehatan.tanggal;
          this.akStatus =
            response.data.mapData.DataPendukung.asuransiKesehatan.status;
          this.kkNomor = response.data.mapData.DataPendukung.kk.nomor;
          this.kkTanggal = response.data.mapData.DataPendukung.kk.tanggal;
          this.kkStatus = response.data.mapData.DataPendukung.kk.status;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>
